
import { Component, Vue, Prop } from 'vue-property-decorator';
import ChatWithUs from '@/components/ChatWithUs.vue';

@Component({
  components: {
    ChatWithUs,
  },
})
export default class PermissionComponent extends Vue {
  @Prop({ default: true })
  public isLoading!: boolean;
  @Prop({ default: '' })
  public feature!: string;

  public async created() {
    // ignore it
  }
}
